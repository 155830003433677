<template>
  <div v-if="getOrder.length > 0" class="product-order">
    <div class="bloc-title">
      <h1>{{ $t('payment_process.transaction_details') }}</h1>
    </div>

    <back />

    <div class="order-body" v-for="(order, n) in getOrder" :key="n">
      <div class="title-flex">
        <div class="live-order-buy" v-if="order.order_type === 0">
          <img
            src="@/assets/svg/v2/products/buyOrder.svg"
            alt="Buy order"
            width="16"
            height="16"
          />
          <p>{{ $t('search.buy') }}</p>
        </div>
        <div class="live-order-sell" v-if="order.order_type === 1">
          <img
            src="@/assets/svg/v2/products/buyOrder.svg"
            alt="Sell order"
            width="16"
            height="16"
          />
          <p>{{ $t('search.sell') }}</p>
        </div>
        <div class="live-order-block-trade" v-if="order.order_type === 4">
          <img
            src="@/assets/svg/v2/products/blocktrade.svg"
            alt="Sell order"
            width="16"
            height="16"
          />
          <p>Block Trade</p>
        </div>

        <div class="live-order-giver" v-if="order.order_type === 2">
          <img
            src="@/assets/svg/v2/products/swap.svg"
            alt="Giver order"
            width="16"
            height="16"
          />
          <p>Giver</p>
        </div>

        <div class="live-order-taker" v-if="order.order_type === 3">
          <img
            src="@/assets/svg/v2/products/swap.svg"
            alt="Giver order"
            width="16"
            height="16"
          />
          <p>Taker</p>
        </div>

        <div v-if="order.bottles.length === 1" class="bottle-title">
          <div class="ticker">
            <button class="ticker-maitre">{{ getProduct.ticker }}</button>
          </div>
          <button class="bottle-name">{{ getProduct.title }}</button>
        </div>
      </div>
      <div class="product-header"></div>
      <div class="sub-order">
        <div class="order-details">
          <p class="order-detail-title">{{ $t('payment_process.summary') }}</p>

          <div v-for="(bottle, b) in order.bottles" :key="b" class="product-row">
            <p class="product-row-bottle-title">{{ bottle.title }}</p>
            <div class="qp">
              <div class="quantity-price">
                <span v-if="b === 0" class="font-weight">{{ $t('commons.quantity') }}</span>
                <span>{{ bottle.quantity }}</span>
              </div>
              <div v-if="order.order_type !== 3 && order.order_type !== 2" class="quantity-price">
                <span v-if="b === 0" class="font-weight">{{ $t('settings.invoices.price') }}</span>
                <span class="order-price">{{ bottle.price * bottle.quantity }} EUR</span>
              </div>
            </div>
          </div>

          <div v-if="order.order_type === 3 || order.order_type === 2" class="product-row">
            <p>Contre</p>
          </div>

          <div v-if="order.order_type === 3 || order.order_type === 2" class="product-row margin-bottom">
            <span>{{ order.bottle_swap_name }}</span>
          </div>

          <div v-if="order.order_type !== 0" class="product-row">
            <p>{{ $t('payment_process.selling_fees') }} (2.5%)</p>
            <span>{{ (order.fees + order.fees_vat).toFixed(2) }} EUR</span>
          </div>

          <div class="product-row" v-if="order.order_type === 3 || order.order_type === 2">
            <p>Implied Value</p>
            <span>{{ order.implied_value }} 0 EUR</span>
          </div>

          <div v-if="order.order_type !== 0" class="productSelect total-row">
            <p>{{ $t('payment_process.delivery_fees') }}*</p>

            <el-select
              effect="dark"
              v-model="deliveryMode"
              class="select-payment"
            >
              <el-option
                v-for="item in returnDeliveryChoices(order)"
                :key="item.key"
                :label="item.label"
                :value="item.key"
              >
                <span>{{ item.label }}</span>
              </el-option>
            </el-select>

            <span v-if="deliveryMode !== null" class="payment-fee">{{ order.package_price }} EUR</span>
            <span v-else class="payment-fee">-</span>
          </div>

          <div v-if="getLocationSelected && getFinalPrice(order) < 500" class="productSelect selected-location">
            <p>{{ getLocationSelected.address.name }}</p>
            <button @click="locationChoiceDialog = true">{{ $t('commons.update') }}</button>
          </div>

          <div v-if="order.order_type !== 0" class="inssurance-row">
            <p>{{ $t('payment_process.inssurance_price') }}</p>
            <span>{{ order.insurance_price || 0 }} EUR</span>
          </div>

          <div v-if="order.order_type !== 0" class="productSelect total-row">
            <p>{{ $t('payment_process.payment_method') }}*</p>

            <el-select
              effect="dark"
              v-model="paymentMode"
              class="select-payment"
            >
              <el-option
                v-for="item in paymentModes"
                :key="item.key"
                :label="item.label"
                :value="item.key"
              >
                <span>{{ item.label }}</span>
              </el-option>
            </el-select>

            <span v-if="paymentMode" class="payment-fee">{{ getPaymentMethodFees }} EUR</span>
            <span v-else class="payment-fee">-</span>
          </div>

          <div v-if="order.order_type !== 0" class="product-row total-row">
            <p class="font-weight">{{ $t('payment_process.total_price') }}</p>
            <span id="price">{{ getFinalPrice(order) }} EUR</span>
          </div>

          <div v-if="order.order_type !== 0" class="product-row">
            <p>{{ $t('payment_process.price_tva_amount') }} {{ vat }}%</p>
            <span id="tva">{{ order.fees_vat }} EUR</span>
          </div>
        </div>
        <div class="previews-pictures-bloc">
          <div class="preview-picture">
            <el-image
              class="preview-picture-min"
              v-for="(item, n) in getPictures(order)"
              :key="n"
              :preview-src-list="getPictures(order)"
              :initial-index="n"
              :src="item"
              alt="Bottle picture"
            />
          </div>
        </div>
      </div>
      <div class="seller-infos">
        <p>
          <span v-if="order.order_type !== 0"
            >{{ $t('payment_process.sold_by') + ' ' }}
          </span>
          <span class="seller">{{ ' ' + getUserType(order) }} - {{ getUserCountry(order) }}</span
          >
        </p>
        <p v-if="false">
          Livraison:
          <span class="seller"
            >{{ order.package_price }}EUR ({{ order.package_weight_label }})</span
          >
        </p>
      </div>
      <div class="order-footer">
        <!-- <button
          v-if="order.package_price !== 0 && order.order_type !== 0 && alreadyInCart(order)"
          class="add-to-cart-btn"
          @click="addToCart(order)"
        >
          {{ $t('payment_process.add_to_cart') }}
        </button>

        <button
          v-if="order.package_price !== 0 && order.order_type !== 0 && !alreadyInCart(order)"
          class="add-to-cart-btn"
          @click="removeToCart(order)"
        >
          {{ $t('payment_process.remove_to_cart') }}
        </button> -->

        <button
          v-if="order.package_price !== 0 && order.order_type !== 0 && payWallet"
          :disabled="!paymentMode"
          :style="{ backgroundColor: paymentMode ? ' #2667FF' : 'grey' }"
          @click="paymentStep()"
        >
          {{ $t('payment_process.buy') }}
        </button>

        <button
          v-if="order.order_type === 0"
          @click="openCreateOrderDialog('sell', order)"
        >
          {{ $t('payment_process.sell') }}
        </button>
      </div>
    </div>
  
    <el-dialog v-model="locationChoiceDialog" :title="$t('payment_process.relay_choice')" fullscreen>
      <locationsChoices @close="closeLocationChoiceDialog" />
    </el-dialog>

    <el-drawer v-model="drawerCreateOrder" title="" :size="drawerSize">
      <createOrder @close="drawerCreateOrder = false" :orderType="returnOrderType" :presetPrice="presetPrice" />
    </el-drawer>
  </div>
</template>

<script>
import back from '@/components/layouts/goBack.vue'
import { mapActions, mapGetters } from 'vuex'
import { find, filter, findIndex } from 'lodash'
import locationsChoices from '@/components/modules/dialogs/locationsChoices.vue';
import createOrder from '@/components/modules/dialogs/createOrder.vue'

export default {
  components: {
    back,
    createOrder,
    locationsChoices
  },
  data() {
    return {
      tokenBank: '',
      openDialog: false,
      images: [],
      deliveryMode: null,
      paymentModes: [
        { key: 7, label: this.$t('wallet.order_wallet'), fee: 0 },
        { key: 8, label: 'Paypal (3.5%)', fee: 3.5 },
        { key: 1, label: 'Visa (2%)', fee: 2 },
        { key: 3, label: 'Mastercard (2%)', fee: 2 },
        { key: 6, label: 'American Express (3%)', fee: 3 }
      ],
      paymentMode: null,
      currentZoomUrl: [],
      vat: 20,
      drawerCreateOrder: false,
      locationChoiceDialog: false,
      presetPrice: null,
    }
  },
  computed: {
    ...mapGetters({
      getProduct: 'getProduct',
      getOrder: 'getOrder',
      getLists: 'getLists',
      wallet: 'getWallet',
      getLocations: 'getLocations',
      getLocationSelected: 'getLocationSelected',
      getUserDetails: 'getUserDetails',
      getCart: 'getCart',
      getCreateOrderDialog: 'getCreateOrderDialog'
    }),
    uniqueOrder() {
      if (this.getOrder.length > 0) {
        return find(this.getOrder, { uuid: this.getOrder[0].uuid })
      }
      return []
    },
    getPaymentMethodFees() {
      const findPaymentMethod = find(this.paymentModes, {
        key: this.paymentMode,
      })

      return parseFloat((findPaymentMethod.fee * this.uniqueOrder.total) / 100).toFixed(2)
    },
    payWallet() {
      if (this.paymentMode === 7) {
        if (this.wallet?.available_amount >= this.uniqueOrder.total) {
          return true;
        }
        return false;
      } else {
        return true;
      }
    },
    drawerSize() {
      if (window.innerWidth < 500) {
        return '100%';
      }
      return '40%';
    },
    returnOrderType() {
      return 'sell';
    }
  },
  async mounted() {
    this.getClient();
    const token = this.$route.query.token;

    if (token) {
      await this.getOrderActions({ uuid: token }).then((orders) => {
        this.product(orders[0]);
      })
    }

    if (!token && this.getOrder.length === 0) {
      this.$router.go('-1')
    }

    this.$store.commit('SET_LOCATION_SELECTED', null);
  },
  methods: {
    ...mapActions({
      product: 'product',
      getOrderActions: 'getOrder',
      myWallet: 'myWallet',
      location: 'location',
      changeDeliveryAddress: 'changeDeliveryAddress',
      getClient: 'getAccountDetails'
    }),
    openCreateOrderDialog(type, order) {      
      this.firstTimeType = type;

      if (type === 'sell') {
        if (!this.getUserDetails?.agree.sell) {
          this.dialogFirstTime = true;
        } else {
          this.presetPrice = order.bottles[0];
          this.$store.commit('CREATE_ORDER_DIALOG', 'sell');
          this.drawerCreateOrder = true;
        }
      }
    },
    returnDeliveryChoices(order) {
      if (this.getFinalPrice(order) > 500) {
        this.deliveryMode = 0;
        return [
          {
            key: 0,
            label: this.$t('payment_process.ups_1')
          }
        ]
      }
      return [
        {
          key: 0,
          label: this.$t('payment_process.ups_1')
        },
        {
          key: 1,
          label: this.$t('payment_process.ups_2')
        }
      ]
    },
    getFinalPrice(order) {
      const findPaymentMethod = find(this.paymentModes, { key: this.paymentMode })

      if (order) {
        if (this.paymentMode) {
          return parseFloat(this.uniqueOrder.total + (findPaymentMethod.fee * this.uniqueOrder.total) / 100).toFixed(2)
        } else {
          return parseFloat(this.uniqueOrder.total).toFixed(2)
        }
      } else {
        return 0
      }
    },
    getFees(order) {
      return parseFloat(
        order.quantity * (((order.price / 100) * 2.5) / 100).toFixed(2)
      )
    },
    getShippingPrice() {
      return 5.99
    },
    getUserType(order) {
      if (order.user.type) {
        return this.$t('product_page.orders.pro')
      } else {
        return this.$t('product_page.orders.individual')
      }
    },
    getUserCountry(order) {
      const findCountry = find(this.getLists.country, {
        alpha2: order.user.country,
      })

      if (findCountry) {
        return findCountry.nom_en_gb
      }
      return 'N/A'
    },
    getPictures(order) {
      return order.pictures
    },
    closeLocationChoiceDialog() {
      this.locationChoiceDialog = false;

      if (!this.getLocationSelected) {
        this.deliveryMode = 0;
      }
    },
    paymentStep() {
      if (this.paymentMode) {
        const saveLS = {
          uuid: this.getOrder[0].uuid,
          paymentMode: this.paymentMode,
          price: this.getFinalPrice(this.getOrder[0]),
          title: this.getProduct.title,
          fee: this.getFees(this.getOrder[0]),
          shipping: this.getShippingPrice(this.getOrder[0]),
        }

        localStorage.setItem('orderCart', JSON.stringify(saveLS));
        this.$router.push('/order/payment');
      }
    },
    alreadyInCart(order) {
      const findSameOrderInCart = find(this.getCart, (p) => {        
        return p.uuid === order.uuid;
      })      
      
      if (findSameOrderInCart) {
        return false;
      } else {
        return true;
      }
    },
    addToCart(order) {
      this.$store.commit('ADD_PRODUCT_CART', order);
    },
    removeToCart(order) {
      const findIndexSameOrderInCart = findIndex(this.getCart, (p) => {        
        return p.uuid === order.uuid;
      });

      if (findIndexSameOrderInCart !== null) {
        this.$store.commit('REMOVE_PRODUCT_CART', findIndexSameOrderInCart);
      }
    }
  },
  watch: {
    paymentMode() {
      if (this.paymentMode === 7) {
        this.myWallet();
      }
      this.getFinalPrice()
    },
    async deliveryMode() {
      if (this.deliveryMode === 0) {

        this.$store.commit('SET_LOCATION_SELECTED', null);
        this.locationChoiceDialog = false;

        const payload = {
          uuid: this.getOrder[0].uuid,
          shipment: 0
        }
        this.getOrderActions(payload);
        
      } else if (this.deliveryMode === 1) {
        await this.location().then(() => {
          const findRelayReference = find(this.getUserDetails.address.delivery_multiple_address, (l) => {
            return l.is_relay_reference;
          })

          if (findRelayReference) {
            this.deliveryMode = 1;

            const findRelayInList = find(this.getLocations, (l) => {
              return l.address.name === findRelayReference.name;
            })

            if (findRelayInList) {
              findRelayInList.save = 1;
              this.$store.commit('SET_LOCATION_SELECTED', findRelayInList);
            } else {
              this.locationChoiceDialog = true;
            }
          } else if (!this.getLocationSelected) {
            this.locationChoiceDialog = true;
          }
        });
      }
    },
    async getLocationSelected() {
      const payload = {
        uuid: this.getOrder[0].uuid,
        shipment: 0
      }

      if (this.getLocationSelected) {
        payload.shipment = 1;

        const findRelayIsReference = filter(this.getUserDetails.address.delivery_multiple_address, (l) => {
          return l.is_relay;
        })
  
        if (findRelayIsReference.length === 0) {
          const payloadRelay = {
            name: this.getLocationSelected.address.name,
            address: this.getLocationSelected.address.address,
            city: this.getLocationSelected.address.city,
            country: this.getLocationSelected.address.country,
            region: this.getLocationSelected.address.region,
            zip: this.getLocationSelected.address.zip,
            is_relay_reference: this.getLocationSelected.save === 1 || this.getLocationSelected.is_relay_reference ? true : false,
            is_relay: true
          }
  
          this.getUserDetails.address.delivery_multiple_address.push(payloadRelay)
        } else {
          const findIndexReference = findIndex(this.getUserDetails.address.delivery_multiple_address, (l) => {
            return l.is_relay_reference && this.getLocationSelected.save === 1;
          })

          const findIndexSimpleRelay = findIndex(this.getUserDetails.address.delivery_multiple_address, (l) => {
            return l.is_relay;
          })

          if (findIndexReference !== -1) {
            this.getUserDetails.address.delivery_multiple_address[findIndexReference] = {
              name: this.getLocationSelected.address.name,
              address: this.getLocationSelected.address.address,
              city: this.getLocationSelected.address.city,
              country: this.getLocationSelected.address.country,
              region: this.getLocationSelected.address.region,
              zip: this.getLocationSelected.address.zip,
              is_relay_reference: true,
              is_relay: true
            }
          } else if (findIndexSimpleRelay !== -1) {
            this.getUserDetails.address.delivery_multiple_address[findIndexSimpleRelay] = {
              name: this.getLocationSelected.address.name,
              address: this.getLocationSelected.address.address,
              city: this.getLocationSelected.address.city,
              country: this.getLocationSelected.address.country,
              region: this.getLocationSelected.address.region,
              zip: this.getLocationSelected.address.zip,
              is_relay_reference: this.getLocationSelected.save === 1 || this.getLocationSelected.is_relay_reference ? true : false,
              is_relay: true
            }
          }
        }
  
        await this.changeDeliveryAddress({ address: this.getUserDetails.address.delivery_multiple_address }).then(async() => {
          this.getOrderActions(payload);
        }).catch(() => {})
      }
    }
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root.scss';

.product-header,
.identification,
.product-origin,
.sell-info,
.origin-info,
.product-body,
.description,
.order-body,
.order-body button,
.product-history-filters,
.product-history-compare,
.product-history-chart-choice,
.select-picture,
.preview-picture,
.preview-picture-min,
.create-order,
.infos-data-chart,
.back,
.product-description-header,
.error-bottle,
.product-history-infos-bloc,
.bottle-title {
  display: flex;
}

.switch-for {
  color: $grey40;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}

.font-weight {
  font-weight: bold;
}

.live-order-taker {
  display: flex;
  border-radius: 50px;
  border: 1px solid $border;
  background: #ffffff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.16);
  height: 27px;
  min-width: 65px;
  justify-content: center;
  align-items: center;
  padding: 0 5px;

  img {
    height: 15px;
    padding: 3px;
    width: auto;
    background: #a855f7;
    border-radius: 50%;
  }

  p {
    color: black;
    font-size: 15px;
    font-weight: 500;
    margin-left: 6px;
  }
}

.product-order {
  padding: 30px 50px;
  margin: auto;

  .bloc-title {
    margin-bottom: 15px;
  }
}

.order-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.bottle-title,
.ticker {
  flex-direction: row;
  align-items: center;

  p {
    color: $grey40;
  }
}

.margin-bottom {
  margin-bottom: 20px;
}

.bottle-title {
  gap: 5px;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.ticker {
  p {
    padding-left: 7.5px;
  }
}

.product-header {
  justify-content: center;
  flex-direction: column;
}

.order-footer {
  padding-top: 15px;
  width: 100%;

  button {
    display: flex;
    width: 100px;
    padding: 14px 20px;
    justify-content: center;
    align-items: center;
    border-radius: 52px;
    background: $blue;
    border: none;
    color: white;
    font-weight: bold;
  }

  button.refuse-btn {
    background: none;
    color: $grey40;
  }
}

.ex-selected {
  border: 2px solid $blue !important;
}

.order-body {
  margin-left: 4vw;
  margin-right: 4vw;
  margin: 30px 0;
  max-width: 1500px;
  border-radius: 12px;
  border: 1px solid $border;
  background: #333;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
}

.order-details {
  border-radius: 12px;
  border: 1px solid $border;
  background: #ffffff32;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 15px;
  padding-bottom: 15px;
  color: white;
  min-width: 550px;
  margin-bottom: 11px;

  p.order-detail-title {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 8px;
    font-weight: bold;
    position: absolute;
  }

  div.product-row,
  div.inssurance-row,
  div.priceRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }

  div.product-row {
    align-items: flex-end;
  }

  div.productSelect,
  div.priceRow {
    display: flex;
    flex-direction: row;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;

    .select-payment {
      margin-left: 20px;
      max-width: 200px;
    }
  }

  div.qp {
    display: flex;
    flex-direction: row;
  }

  div.quantity-price {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    margin-left: 10px;
    min-width: 80px;

    span:nth-child(2) {
      margin-top: 20px;
    }
  }

  div.total-row {
    padding-top: 10px;
  }

  span#price {
    font-weight: bold;
  }
  span.payment-fee {
    margin-left: auto;
  }

  span.order-price {
    padding-left: 30px;
  }

  div>span {
    min-width: 100px;
    text-align: right;
  }
}

.preview-picture {
  margin-left: 20px;
  display: flex;
  max-width: 420px;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: left;
  overflow: hidden;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  .preview-picture-min {
    cursor: pointer;
    width: 150px;
    height: 157px;
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    margin-left: 15px;

    img {
      width: 80px;
      height: 80px;
    }
  }
}

.live-order-sell {
  display: flex;
  border-radius: 50px;
  border: 1px solid $border;
  background: #ffffff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.16);
  height: 27px;
  min-width: 65px;
  justify-content: center;
  align-items: center;
  margin-left: 0;

  img {
    background: #f43f5e;
    border-radius: 50%;
  }

  p {
    color: black;
    font-size: 15px;
    font-weight: 500;
    margin-left: 6px;
  }

  span {
    color: $grey40;
    font-size: 15px;
    font-weight: 500;
    margin-left: 2px;
  }
}

.live-order-block-trade {
  display: flex;
  border-radius: 50px;
  border: 1px solid $border;
  background: #ffffff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.16);
  height: 27px;
  min-width: 65px;
  justify-content: center;
  align-items: center;
  padding: 0 5px;

  img {
    height: 15px;
    padding: 3px;
    width: auto;
    background: $blue;
    border-radius: 50%;
  }

  p {
    color: black;
    font-size: 15px;
    font-weight: 500;
    margin-left: 6px;
  }

  span {
    color: $grey40;
    font-size: 15px;
    font-weight: 500;
    margin-left: 2px;
  }
}

.live-order-buy {
  display: flex;
  border-radius: 50px;
  border: 1px solid $border;
  background: #ffffff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.16);
  height: 27px;
  min-width: 65px;

  justify-content: center;
  align-items: center;

  img {
    background: #84cc16;
    border-radius: 50%;
  }

  p {
    color: black;
    font-size: 15px;
    font-weight: 500;
    margin-left: 6px;
  }

  span {
    color: $grey40;
    font-size: 15px;
    font-weight: 500;
    margin-left: 2px;
  }
}

.selected-location {
  color: $subtitle;
  font-size: 15px;
  margin-top: 5px;

  button {
    margin-left: 5px;
    padding: 1px 5px;
    background: transparent;
    border: none;
  }
}

.bottle-name {
  display: flex;
  text-align: start;
  word-break: keep-all;
  color: white;
  font-size: 15px;
  font-weight: bold;
  border: 0;
  background: transparent;
}
.ticker-maitre {
  border-radius: 6px;
  border: 1px solid $border;
  color: $grey40;
  background: transparent;
  padding: 3px;
}

.seller-infos {
  display: flex;
  flex-direction: column;

  p {
    color: $grey40;
  }

  span {
    color: $grey40;
    padding-bottom: 30px;
  }

  span.seller {
    color: white;
    padding-bottom: 5px;
  }
}

.preview-picture::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.preview-picture::-webkit-scrollbar-track {
  background: #fff;
}

.preview-picture::-webkit-scrollbar-thumb {
  background-color: #d1dddd;
  border-radius: 12px;
}

.preview-picture::-webkit-scrollbar-thumb:hover {
  background-color: $base-color;
}

.sub-order {
  width: 100%;
  justify-content: space-between;
  padding-top: 20px;
  display: flex;
  flex-direction: row;
}

.title-flex {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.previews-pictures-bloc {
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
  max-width: 900;
  gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
}

.preview-picture-min {
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 12px;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: auto;
    display: block;
  }
}

.add-to-cart-btn {
  width: 180px!important;
  margin-right: 10px;
}

@media screen and (max-width: 768px) {
  .product-header,
  .identification,
  .product-origin,
  .sell-info,
  .origin-info,
  .product-body,
  .description,
  .order-body,
  .order-body button,
  .product-history-filters,
  .product-history-compare,
  .product-history-chart-choice,
  .select-picture,
  .create-order,
  .infos-data-chart,
  .product-description-header,
  .error-bottle,
  .product-history-infos-bloc,
  .bottle-title {
    flex-direction: column;
    align-items: flex-start;
  }

  .bottle-title {
    margin-left: 8px;
  }

  .order-footer {
    flex-direction: column;
    align-items: center;
  }

  .live-order-taker,
  .live-order-sell,
  .live-order-block-trade,
  .live-order-buy {
    flex-direction: row;
  }

  .product-order {
    padding: 20px 30px;
  }

  .order-body {
    margin-left: 2vw;
    margin-right: 2vw;
    padding: 10px;
  }

  .order-details {

    p.order-detail-title {
      padding-top: 8px;
      font-weight: bold;
    }
    padding-left: 15px;
    padding-right: 15px;
  }

  .order-details div.product-row,
  .order-details div.priceRow,
  .order-details div.productSelect,
  .order-details div.priceRow {
    padding-left: 15px;
    padding-right: 15px;
  }

  .preview-picture {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
  }

  .preview-picture-min {
    width: 110px;
    height: 110px;
  }

  .sub-order {
    flex-direction: column;
    align-items: center;
  }

  .title-flex {
    gap: 5px;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (max-width: 500px) {

  .product-order {
    padding: 10px 0px 0px;
    margin: auto;
  
    .back {
      margin-left: 10px;
    }
    .bloc-title {
      margin-bottom: 15px;
      margin-left: 10px;
    }
  }

  .bottle-title {
    flex-direction: row;
    align-items: flex-end;
    margin-left: 0;
  }

  .order-body {
    padding: 5px;
  }

  .order-details {
    min-width: initial;
    padding-left: 0;
    padding-right: 0;

    span.payment-fee {
      margin-left: 20px;
      margin-right: -90px;
      width: 100%;
    }

    div.productSelect,
    .total-row {
      justify-content: space-between;
    }

    div.priceRow {
      display: flex;
      flex-direction: row;
      padding-left: 30px;
      padding-right: 30px;
      align-items: center;
  
      .select-payment {
        margin-left: 0px;
        max-width: 100px;
      }
    }

    p.order-detail-title {
      position: relative;
      padding-left: 15px;
      padding-top: 8px;
      font-weight: bold;
    }

    div>span {
      min-width: 40px;
      text-align: right;
    }

    div.inssurance-row {
      padding-left: 15px;
      padding-right: 15px;
    }

    .productSelect {
      flex-wrap: wrap;
      justify-content: space-between!important;

      .select-payment {
        margin-left: 0!important;
        margin-top: 5px;
      }

      .payment-fee {
        max-width: 60px;
        margin-left: 0;
        margin-right: 0;
        text-align: right;
      }
    }
  }

  .preview-picture {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    margin-left: -15px;
  }

  .preview-picture-min {
    width: 80px;
    height: 80px;
  }

  .previews-pictures-bloc {
    display: flex;
    margin: 0px 0px 0px 0px;
    flex-wrap: wrap;
  }

  .sub-order {
    padding-top: 10px;
  }

  .sub-order,
  .title-flex {
    width: 100%;
  }

  .product-row-bottle-title {
    visibility: hidden;
    font-size: 10px;
  }

  .live-order-taker,
  .live-order-sell,
  .live-order-block-trade,
  .live-order-buy {
    flex-direction: row;
    align-items: center;
  }

  .order-footer {

    button {
      align-items: center;
    }
  }
}
</style>